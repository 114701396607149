<template>
	<v-container fixed>
		<Progress :error="error" :loading="loading" type="overlay"></Progress>

		<template v-if="advice['movie'] && advice['movie'].length">
			<v-layout justify-center class="title">Рекомендации</v-layout>

			<channel-slide :items="advice['movie']"></channel-slide>
		</template>

		<template v-if="advice['series'] && advice['series'].length">
			<v-layout justify-center class="title">Вам могут понравитья сериалы</v-layout>

			<channel-slide :items="advice['series']"></channel-slide>
		</template>

		<template v-if="advice['anime'] && advice['anime'].length">
			<v-layout justify-center class="title">Вам могут понравитья аниме</v-layout>

			<channel-slide :items="advice['anime']"></channel-slide>
		</template>

		<template v-if="sets && sets.length">
			<v-layout justify-center>
				<router-link class="link-with-effect title" :to="{ name: 'setList'}">Подборки</router-link>
			</v-layout>

			<channel-slide :items="sets" :link="{ name: 'setList'}" type="set"></channel-slide>
		</template>

		<template v-if="userSets && userSets.length">
			<div v-for="item in userSets" :key="item.set_id">
				<v-layout justify-center>
					<router-link class="link-with-effect title" :to="{ name: 'setPage', params: { slug: item.set.slug }}" v-html="`Подборка \u{22}${item.set.name}\u{22}`"/>
				</v-layout>

				<channel-slide :items="item.set.channels" :link="{ name: 'setList'}"></channel-slide>
			</div>
		</template>

		<template v-if="last['movie'] && last['movie'].length">
			<v-layout justify-center>
				<router-link
					class="link-with-effect title"
					:to="{ name: 'channelList', params: { section: 'movie', sort: 'fresh' }}"
				>Свежие фильмы</router-link>
			</v-layout>

			<channel-slide
				:items="last['movie']"
				:link="{ name: 'channelList', params: { section: 'movie', sort: 'fresh' }}"
			></channel-slide>
		</template>

		<template v-if="last['series'] && last['series'].length">
			<v-layout justify-center pt-10>
				<router-link
					class="link-with-effect title last"
					:to="{ name: 'channelList', params: { section: 'series', sort: 'fresh' }}"
				>Обновления сериалов</router-link>
			</v-layout>

			<channel-slide
				:items="last['series']"
				:link="{ name: 'channelList', params: { section: 'series', sort: 'fresh' }}"
				type="video"
			></channel-slide>
		</template>
	</v-container>
</template>
<script>
import ChannelSlide from "../components/channel-slide.vue";

function fetchData() {
	return window.fetchData("/api/home", []);
}
export default {
	components: { ChannelSlide },
	data() {
		return {
			loading: true,
			advice: { series: null, movie: null, anime: null },
			sets: null,
			error: null,
			last: { series: null, movie: null },
			updateInterval: null,
			userSets: null
		};
	},
	created() {
		document.title = window.location.hostname;
		this.updateInterval = setInterval(this.fetchData, 600000);
		if (process.env.NODE_ENV != 'production') {
			this.$nextTick(() => {
				if (this.loading == true) this.fetchData();
			});
		}
	},
	methods: {
		async fetchData() {
			let data = await fetchData();
			this.setData(data);
		},
		setData(data) {
			this.loading = false;
			if (data.error) {
				this.error = data.error;
				return;
			}
			this.advice = data.advice;
			this.sets = data.set;
			this.last = data.last;
			this.userSets = data.user_sets;
		}
	},
	beforeDestroy: function() {
		clearInterval(this.updateInterval);
	},
	async beforeRouteEnter(to, from, next) {
		let data = await fetchData();

		next(vm => {
			vm.setData(data);
		});
	},
	async beforeRouteUpdate(to, from, next) {
		this.loading = true;
		this.fetchData();
		next();
	}
};
</script>
<style scoped>
.container .title {
	color: black;
	margin-top: 40px;
}
.container .title.last {
	margin-top: 0px;
}
.theme--dark .container .title {
	color: white;
}
</style>