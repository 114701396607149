<template>
	<v-container class="channel-gallery">
		<v-slide-group v-model="slide" center-active show-arrows v-if="items" :class="type">
		<v-slide-item class="slide-item" v-for="item in items" :key="item.id ? item.id : item.v_id">
			<Channel :item="item" v-if="type == 'channel'" />
			<Video :item="item" :re="re" v-else-if="type == 'video'" />
			<Set :item="item" v-else />
		</v-slide-item>

		<slot name="more">
			<v-slide-item v-if="link">
				<router-link :to="link">
				<v-img
					:src="'/image/more-' + (type == 'channel' ? '150' : '180') + '.jpg'"
					:srcset="'/image/more-' + (type == 'channel' ? '300' : '360') + '.jpg 2x'"
					:width="type == 'channel' ? 150 : 180"
					:height="type == 'channel' ? 222 : 180"
					eager
				>
					<v-container fill-height fluid text-center>
					<div class="channel-title">Показать все</div>
					</v-container>
				</v-img>
				</router-link>
			</v-slide-item>
		</slot>
		</v-slide-group>
	</v-container>
</template>
<script>
import Channel from "./items/channel.vue";
import Set from "./items/set.vue";
import Video from "./items/video.vue";

export default {
	props: {
		items: [Array, Object],
		link: Object,
		type: {
			type: String,
			default: "channel"
		},
		active: Number,
		strip: {
			type: String,
			default: null
		}
	},
	components: { Channel, Set, Video },
	data() {
		return {
		slide: this.active,
		re: null
		};
	},
	created() {
		if (this.strip)
		this.re = new RegExp(
			"^.*? - \\s*" +
			(this.strip == "season" ? "(\\d+\\s+сезон\\s*,?|)" : ""),
			"i"
		);
	}
};
</script>
<style lang="scss" scoped>
.channel-title {
	color: white;
	font-size: 18px;
}
.channel-gallery ::v-deep {
	.channel {height: 224px;}
	.v-slide-group__prev,
	.v-slide-group__next {
		background: transparent;
		position: relative;
		min-width: 40px;
		z-index: 2;
		i {
		color: green !important;
		background: white;
		font-size: 48px;
		border-radius: 50%;
		opacity: 0.8;
		padding: 7px 7px 7px 8px;
		}
		.v-icon--disabled {
		display: none;
		}
	}
	.v-slide-group__prev {
		position: absolute;
		top: 70px;
		left: 20px;
	}
	.v-slide-group__next {
		position: absolute;
		top: 70px;
		right: 20px;
	}
	.video-title {
		padding: 5px;
	}
}
.video ::v-deep {
	.v-slide-group__prev,
	.v-slide-group__next {
		top: 40px;
	}
}
.channel ::v-deep {
	.v-slide-group__prev,
	.v-slide-group__next {
		top: 95px;
	}
}
.slide-item {
	margin-right: 20px;
}
</style>
