<template>
	<router-link :to="{ name: 'video', params: { id: item.v_id }}">
		<div class="video-inner" :class="item.view == 2 || item.view == 1 ? 'last' : ''">
			<v-img
				:src="getVideoPhoto(item.v_key, item.preview, 160)"
				:srcset="getVideoPhoto(item.v_key, item.preview, 360) + ' 2x'"
				:alt="item.v_title"
				max-width="180"
				style="padding-bottom: 66.66%"
				height="112"
				eager
			></v-img>
			<div class="ic">{{ item.duration|sToTime }}</div>
			<v-progress-linear :value="getPosition(item.v_id)" v-if="item.view" class="view"></v-progress-linear>
		</div>
		<div class="video-title" v-html="stripTitle(item.v_title)"></div>
	</router-link>
</template>
<script>
import { Helper } from "../../mixins/Helper";

export default {
	props: ["item", "re"],
	mixins: [Helper],
	data() {
		return {};
	},
	filters: {
		sToTime(value) {
			let hours = parseInt(Math.floor(value / 3600));
			let minutes = parseInt(Math.floor((value - hours * 3600) / 60));
			let seconds = parseInt(
				(value - (hours * 3600 + minutes * 60)) % 60
			);

			let dMins = minutes > 9 ? minutes : "0" + minutes;
			let dSecs = seconds > 9 ? seconds : "0" + seconds;

			return (hours ? hours + ":" : "") + dMins + ":" + dSecs;
		}
	},
	methods: {
		stripTitle(value) {
			if (!this.re) return value;
			return value.replace(this.re, "");
		},
		getPosition(v_id) {
			let pos = localStorage.getItem("pos" + v_id);
			if (pos) return pos;
			else return 100;
		},
	}
};
</script>
<style scoped>
.video-title {
	width: 180px;
	padding: 0px;
	word-wrap: break-word;
	line-height: 1.2;
	color: black;
	padding-top: 3px;
	white-space: normal;
}
.theme--dark .video-title {
	color: white;
}
.ic {
	opacity: 0.5;
	position: absolute;
	right: 5px;
	bottom: 5px;
	padding: 2px 4px 2px 3px;
	border-radius: 3px;
	background: #000;
	background: rgba(0, 0, 0, 0.7);
	color: #fff;
}
.video-card:hover .ic {
	opacity: 1;
}
.video-inner {
	position: relative;
}
.router-link-active .video-inner > div[role="img"]:before,
.video-inner.last > div[role="img"]:before {
	display: block;
	content: "";
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	border: 3px solid #d7f6a9;
}
.video-inner.last > div[role="img"]:before {
	border: 3px solid #416d00;
}
.view {
	margin-top: -4px;
}
</style>
